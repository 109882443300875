import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ClipLoader from 'react-spinners/ClipLoader';

const FormDeleteOrder = ({ BaseUrl, deleteId, deleteName }) => {
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    setLoading(true);
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    };
    const response = await fetch(
      `${BaseUrl}api/orders/delete/${deleteId}`,
      requestOptions
    );
    if (response.ok) {
      window.location.href = '/dashboard';
    }
  };
  return (
    <div className="text-center">
      {loading? 
      <ClipLoader
      className="float-center"
      size={50}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
      : 
      <div>
        <h4 className="mt-3">
        Вы точно хотите
        <br />
        удалить заказ "{deleteName}" ?
      </h4>
      <Button variant="danger" className="mb-5 mt-2" onClick={handleDelete}>
        Да, удалить
      </Button>
      </div>
      }
    </div>
  );
};
export default FormDeleteOrder;
