import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { IoStatsChartSharp } from "react-icons/io5";
import big from '../big.svg';

import l1 from '../img/l1.png';
import l2 from '../img/l2.png';
import l3 from '../img/l3.png';



import Carusel from './Carusel';

const IndexPage = ({BaseUrl}) => {


  
  let date = new Date().toLocaleString('ru', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
  var days = [
    'Воскресенье',
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота'
  ];
  var d = new Date();
  var n = d.getDay();

  const [rate, setRate] = useState('')
  
  const [vl, setVl] = useState('') 
  const [ns, setNs] = useState('') 
  const [msk, setMsk] = useState('') 
  const [tok, setTok] = useState('') 

  const [loadRate, setLoadRate] = useState(false)
  useEffect(() => {
    const getData = async () => {
      const response = await fetch(`${BaseUrl}api/orders/rate`);
      let actualData = await response.json();
      setRate((actualData['rate'])*100);
      setLoadRate(true)
      setVl(actualData['vl'])
      setNs(actualData['ns'])
      setMsk(actualData['msk'])
      setTok(actualData['tok'])
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-12 text-center text-lg-start'>
          <Link to="/" rel="noreferrer"><img src={big} alt="logo" /></Link>
          </div>
        </div>
      </div>
      
      <div className="container shadow p-3 rounded mt-3 white">
      <div className="row">
        <div className='col-12 col-lg-4 text-center text-lg-start'>
          <p className='HomeCurrentTime mb-0'>{date}, {days[n]}<br/><b>Владивосток</b>:{vl} <b>Новосибирск</b>:{ns} <b>Москва</b>:{msk} <b>Токио</b>:{tok}</p>
        </div>
        <div className="col-12 col-lg-4 text-center">
         <p className='HomeRateText mt-2 mb-0'><IoStatsChartSharp style={{marginTop:'-5px'}}/> {loadRate? `Курсы валют: 100 JPY = ${rate} ₽`: 'Загрузка...'}</p>
        </div>
        <div className='col-12 col-lg-4 mt-2 mt-lg-1 mt-lg-0 text-center text-lg-end'>
        <Link to="/dashboard" rel="noreferrer"><button type="button" className="btn btn-dark float-none float-lg-end" style={{background:'#152536', borderColor:'#152536', fontWeight:700, marginTop:'-3px'}}>Войти</button></Link>
        <p className='float-end me-0 me-lg-3 HomeRightHeaderText mb-0 mt-2 mt-lg-0'>
          <Link to="tel:819045979795" className='HomeLinkTel' rel="noreferrer"><FaPhoneAlt/> + 8190-4597-9795</Link><br/>
          <Link to="mailto:mitsuefu@gmail.com" className='HomeLinkEmail' rel="noreferrer"><IoMdMail /> mitsuefu@gmail.com</Link>
        </p>
        </div>
      </div>
    </div>
    
    <div className='container mt-4 p-0 mb-4 d-none d-lg-block'>
      <div className='row'>
        <div className='col-12'>
        <Carusel />
        </div>
      </div>
    </div>

    <div className='container shadow p-3 rounded mt-3 white'>
      <div className='row'>
        <div className='col-12 mt-4'>
        <p className='HomePremTitle'>MITSUEFU EAST LINE CO., Ltd.</p>
        <p className='HomePremText'>Помогает клиентам покупать товары, транспортные средства и различное оборудование в Японских интернет магазинах и Yahoo</p>

        <p className='HomePremTitle'>Доставка</p>
        <p className='HomePremText'>Доставка может быть организована в разные страны, а так же в пределах Японии</p>
        
        <p className='HomePremTitle'>О нас</p>
        <p className='HomePremText'>Мы компания, которая предлагает своим клиентам возможность приобритения любых лотов: Диски, кузовные детали, обвесы, товары для дома, часы, аксессуары, одежда ведущих брендов и много другое - всё это вы можете купить, воспользовавшись нашими услугами</p>
        
        <p className='HomePremTitle'>Упаковка</p>
        <p className='HomePremText'>По желанию переупакуем или упакуем товар</p>
        
        <p className='HomePremTitle'>Отгрузка</p>
        <p className='HomePremText'>Обеспечиваем отгрузку по необходимому адресу</p>
        
        <p className='HomePremTitle'>Интернет магазины</p>
        <p className='HomePremText'>Возможность делать покупки в зарубежных интернет магазинах с доставкой в Россию</p>
        </div>
      </div>
      <div className='row mb-3'>
      <img src={l1} alt="logo" className='premLogo'/>
      <img src={l2} alt="logo" className='premLogo'/>
      <img src={l3} alt="logo" className='premLogo'/>
      </div>
    </div>
    <div className='container mb-5 mt-4 text-center'>
      <div className='row'>
        <div className='col-12'>
          <p className='FooterContacts'>+ 8190-4597-9795 | mitsuefu@gmail.com</p>
          <p style={{fontSize:'12px'}}>Copyright © 2023 MITSUEFU EAST LINE CO., Ltd.</p>
        </div>
      </div>
    </div>
    </div>
    
  );
};
export default IndexPage;
