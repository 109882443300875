import { Outlet } from "react-router-dom";
import HeaderClient from "../components/HeaderClient";

export const ClientLayouts = ({deleteItem, BaseUrl}) => {
  return (
    <div className="container mb-5">
      <div className="row">
      <HeaderClient  deleteItem={deleteItem} BaseUrl={BaseUrl}/>
        <Outlet />
      </div>
    </div>
  )
}
export default ClientLayouts