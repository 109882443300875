import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import s1 from '../img/1.webp';
import s2 from '../img/2.webp';
import s3 from '../img/3.webp';

const Carusel = () => {
    return (
        <Carousel showArrows={true} autoPlay={true} preventMovementUntilSwipeScrollTolerance={true} showIndicators={true} showThumbs={false} swipeable={true} infiniteLoop={true} > 
            <div>
            <img src={s1} alt="logo" />
            </div>
            <div>
            <img src={s2} alt="logo" />
            </div>
            <div>
            <img src={s3} alt="logo" />
            </div>
        </Carousel>
    );
}
export default Carusel