import Table from 'react-bootstrap/Table';
import { useState, useEffect } from 'react';
import { IoMdLogOut } from 'react-icons/io';
import Modal from 'react-bootstrap/Modal';


import OrderRowAdmin from './OrderRowAdmin';
import HeaderAdmin from './HeaderAdmin';
import FormAddOrder from './FormAddOrder';
import FormDeleteOrder from './FormDeleteOrder';
import FormEditOrder from './FormEditOrder';
import CardClient from './CardClient';


const HomeAdmin = ({ BaseUrl, deleteItem }) => {
  const dataSkeleteon = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const [data, setData] = useState(null);
  const [rate, setRate] = useState('Загрузка...');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [showCardClient, setShowCardClient] = useState(false);
  const [clientId, setClientID] = useState(null)
  const handleCardClient = (id) => {
    setShowCardClient(!showCardClient)
    setClientID(id)
  };

  const [showEdit, setshowEdit] = useState(false);
  const handleEdit = () => setshowEdit(false);

  const handleShow = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('login');
    localStorage.removeItem('phone');
    localStorage.removeItem('id');
    localStorage.removeItem('total');
    setShow(true);
  };

  const handleShowEdit = (user, login, phone, id, total) => {
    localStorage.setItem('user', user);
    localStorage.setItem('login', login);
    localStorage.setItem('phone', phone);
    localStorage.setItem('id', id);
    localStorage.setItem('total', total);
    setshowEdit(true);
  };

  const [showDelete, setShowDelete] = useState(false);
  const [deleteName, setDeleteName] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = (id, name) => {
    setDeleteName(name);
    setDeleteId(id);
    setShowDelete(true);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(`${BaseUrl}api/orders/`);
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        setData(actualData['orders']);
        setRate(actualData['rate']);
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {/* Модальное добавить заказ */}
      <Modal show={show} onHide={handleClose} fullscreen={true}>
        <div className="container mt-5">
          <Modal.Header closeButton>
            <Modal.Title>Добавить заказ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormAddOrder BaseUrl={BaseUrl} />
          </Modal.Body>
        </div>
      </Modal>
      {/* Модальное редактировать заказ */}
      <Modal show={showEdit} onHide={handleEdit} size="lg" fullscreen={true}>
        <div className="container mt-5">
          <Modal.Header closeButton>
            <Modal.Title>Редактировать заказ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormEditOrder BaseUrl={BaseUrl} deleteId={deleteId} />
          </Modal.Body>
        </div>
      </Modal>
      {/* Модальное удалить заказ */}
      <Modal
        show={showDelete}
        onHide={handleCloseDelete}
        size="lg"
        fullscreen={true}
      >
        <div className="container mt-5">
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormDeleteOrder
              BaseUrl={BaseUrl}
              deleteId={deleteId}
              deleteName={deleteName}
            />
          </Modal.Body>
        </div>
      </Modal>
      {/* Модальное карточка клиента */}
      <Modal show={showCardClient} onHide={handleCardClient} fullscreen={true}>
        <div className="container mt-5">
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body ><CardClient BaseUrl={BaseUrl} clientId={clientId}/></Modal.Body>
        </div>
      </Modal>

      <div className="container">
        <div className="row">
          <div className="col-12">
            <button
              type="button"
              className="btn btn-outline-dark float-end mt-3"
              onClick={deleteItem}
            >
              Выйти <IoMdLogOut style={{ marginTop: -2 }} size={20} />
            </button>
          </div>
        </div>
      </div>

      <HeaderAdmin
        deleteItem={deleteItem}
        rate={rate}
        BaseUrl={BaseUrl}
        data={data}
        setData={setData}
        loading={loading}
        setLoading={setLoading}
        setRate={setRate}
        handleShow={handleShow}
      />
      <div className="container rounded shadow mt-3 white p-0 pt-3 pb-3 text-center placeholder-glow">
        <Table hover responsive="xl">
          <thead>
            <tr>
              <th></th>
              <th>Дата</th>
              <th>Клиент</th>
              <th  style={{width:'100px'}}>№ Заказа</th>
              <th>Магазин</th>
              <th>Название</th>
              <th>Ссылка(код)</th>
              <th  style={{width:'80px'}}>Кол-во</th>
              <th>JPY</th>
              <th>TOTAL JPY</th>
              <th>Итого</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading
              ? dataSkeleteon.map((number, index) => (
                  <tr key={index}>
                    <th>
                      <span className="placeholder col-12 placeholder-lg"></span>
                    </th>
                    <th>
                      <span className="placeholder col-12 placeholder-lg"></span>
                    </th>
                    <th>
                      <span className="placeholder col-12 placeholder-lg"></span>
                    </th>
                    <th>
                      <span className="placeholder col-12 placeholder-lg"></span>
                    </th>
                    <th>
                      <span className="placeholder col-12 placeholder-lg"></span>
                    </th>
                    <th>
                      <span className="placeholder col-12 placeholder-lg"></span>
                    </th>
                    <th>
                      <span className="placeholder col-12 placeholder-lg"></span>
                    </th>
                    <th>
                      <span className="placeholder col-12 placeholder-lg"></span>
                    </th>
                    <th>
                      <span className="placeholder col-12 placeholder-lg"></span>
                    </th>
                    <th>
                      <span className="placeholder col-12 placeholder-lg"></span>
                    </th>
                    <th>
                      <span className="placeholder col-12 placeholder-lg"></span>
                    </th>
                    <th>
                      <span className="placeholder col-12 placeholder-lg"></span>
                    </th>
                  </tr>
                ))
              : data.map((order, index) => (
                  <OrderRowAdmin
                    key={index}
                    order={order}
                    handleShowEdit={handleShowEdit}
                    handleShowDelete={handleShowDelete}
                    handleEdit={handleEdit}
                    handleCardClient={handleCardClient}
                  />
                ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
export default HomeAdmin;
