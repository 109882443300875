import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import ClipLoader from 'react-spinners/ClipLoader';

const FormEditOrder = ({ BaseUrl }) => {
  const [rate, setRate] = useState(0);
  const [clients, setClients] = useState(null);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [put, setPut] = useState(false);
  const { register, handleSubmit } = useForm();
  const [userid, setUserid] = useState(localStorage.user);
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState(null);

  const handleChange = (event) => {
    setTotal((event.target.value * rate).toFixed(0));
  };

  useEffect(() => {}, [total]);

  const onSubmitEdit = async (data) => {
    setPut(true);
    setLoading(true);

    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let currentDate = `${year}-${month}-${day}`;

    data.id_user = userid;
    data.total = total;
    data.id = localStorage.id;

    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };
    const response = await fetch(
      `${BaseUrl}api/orders/patch/${localStorage.id}`,
      requestOptions
    );
    if (response.ok) {
      window.location.href = '/dashboard';
      // setLoading(false);
      // setPut(false);
    } else {
    }
  };

  useEffect(() => {
    const getData = async () => {
      setPut(true);
      try {
        const response = await fetch(`${BaseUrl}api/orders/${localStorage.id}`);
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        setClients(actualData['clients']);
        setStatus(actualData['status']);
        setRate(actualData['rate']);
        setOrder(actualData['order']);
        setTotal(localStorage.total);
        setError(null);
        setPut(false);
      } catch (err) {
        setError(err.message);
        setClients(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  let client = '';
  if (localStorage.user == null) {
    client = '';
  } else {
    client = {
      value: localStorage.user,
      label: `${localStorage.login} - ${localStorage.phone}`,
    };
  }

  return (
    <div className="placeholder-glow formEditskeleton">
      <Form onSubmit={handleSubmit(onSubmitEdit)} className='FormEditOrder'>
        <div className="row">
          <div className="col-12 col-lg-6">
            <Form.Group className="mb-3">
              <Form.Label>Клиент *</Form.Label>
              {loading ? (
                <span className="placeholder col-12 placeholder-lg"></span>
              ) : (
                <Select
                  options={clients}
                  placeholder={'Выбрать клиента'}
                  required
                  onChange={(newValue) => setUserid(newValue.value)}
                  autoFocus
                  defaultValue={client}
                />
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Номер заказа *</Form.Label>
              {loading ? (
                <span className="placeholder col-12 placeholder-lg"></span>
              ) : (
                <Form.Control
                  type="text"
                  disabled={put}
                  {...register('number')}
                  required
                  defaultValue={order.number}
                />
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Магазин</Form.Label>
              {loading ? (
                <span className="placeholder col-12 placeholder-lg"></span>
              ) : (
                <Form.Control
                  type="text"
                  disabled={put}
                  {...register('shop')}
                  defaultValue={order.shop}
                />
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Название</Form.Label>
              {loading ? (
                <span className="placeholder col-12 placeholder-lg"></span>
              ) : (
                <Form.Control
                  type="text"
                  disabled={put}
                  {...register('name')}
                  defaultValue={order.name}
                />
              )}
            </Form.Group>
          </div>
          <div className="col-12 col-lg-6">
            <Form.Group className="mb-3">
              <Form.Label>Код *</Form.Label>
              {loading ? (
                <span className="placeholder col-12 placeholder-lg"></span>
              ) : (
                <Form.Control
                  type="text"
                  disabled={put}
                  {...register('sku')}
                  required
                  defaultValue={order.sku}
                />
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Ссылка</Form.Label>
              {loading ? (
                <span className="placeholder col-12 placeholder-lg"></span>
              ) : (
                <Form.Control
                  type="text"
                  disabled={put}
                  {...register('link')}
                  defaultValue={order.link}
                />
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Кол-во</Form.Label>
              {loading ? (
                <span className="placeholder col-12 placeholder-lg"></span>
              ) : (
                <Form.Control
                  type="number"
                  disabled={put}
                  {...register('quantity')}
                  defaultValue={order.quantity}
                />
              )}
            </Form.Group>
            <div className="row">
              <div className="col-12 col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>JPY</Form.Label>
                  {loading ? (
                    <span className="placeholder col-12 placeholder-lg"></span>
                  ) : (
                    <Form.Control
                      type="number"
                      min={0}
                      disabled={put}
                      {...register('jpy')}
                      defaultValue={order.jpy}
                    />
                  )}
                </Form.Group>
              </div>
              <div className="col-12 col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>TOTAL JPY</Form.Label>
                  {loading ? (
                    <span className="placeholder col-12 placeholder-lg"></span>
                  ) : (
                    <Form.Control
                      {...register('jpy_total')}
                      onChange={handleChange}
                      type="number"
                      min={0}
                      disabled={put}
                      defaultValue={order.jpy_total}
                    />
                  )}
                </Form.Group>
              </div>
              <div className="col-12 col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>Итого</Form.Label>
                  {loading ? (
                    <span className="placeholder col-12 placeholder-lg"></span>
                  ) : (
                    <Form.Control
                      value={total}
                      onChange={(e) => setTotal(e.target.value)}
                      type="number"
                      step="0.01"
                      min={0}
                      disabled={true}
                    />
                  )}
                </Form.Group>
              </div>
              <Form.Group className="mb-3">
                <Form.Label>Статус</Form.Label>
                {loading ? (
                  <span className="placeholder col-12 placeholder-lg"></span>
                ) : (
                  <Form.Select
                    aria-label="Default select example"
                    disabled={put}
                    {...register('id_status')}
                    required
                    defaultValue={order.id_status}
                  >
                    {status.map((status, index) => (
                      <option value={status.id} key={index}>
                        {status.text}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </Form.Group>
              {loading ? '' : <p className="text-end">* Обязательно</p>}
            </div>
          </div>
        </div>
        {put ? (
          <ClipLoader
            className="float-end ms-3"
            size={40}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <Button
            variant="primary"
            type="submit"
            className="float-end"
            disabled={put}
          >
            Сохранить
          </Button>
        )}
        {loading ? (
          <span className="placeholder col-2 placeholder-lg float-end "></span>
        ) : (
          <Form.Check
            className="float-end me-3 mt-1 checkPay"
            type="checkbox"
            id={1}
            value={1}
            label="Оплачен"
            defaultChecked={order.pay}
            {...register('pay')}
          />
        )}
      </Form>
    </div>
  );
};
export default FormEditOrder;
