import { IoIosPrint } from "react-icons/io";
import { useState, useEffect } from 'react';

import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { ComponentToPrint } from './ComponentToPrint';

const CardClient = ({BaseUrl, clientId}) => {

  

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  
  const [data, setData] = useState([])
  const [debt, setDebt] = useState([])
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const response = await fetch(`${BaseUrl}api/users/get_client/${clientId}`);
      let actualData = await response.json();
      setData(actualData['client']);
      setDebt(actualData['debt'])
      setOrders(actualData['orders'])
      setLoading(false)
    };
    getData();
  }, []);

  return (
    <div className="container cardClient placeholder-glow">      
      <div className="row">
        <div className="col-12">
          <h2>
          {loading? <span className="placeholder col-6 placeholder-lg"></span>: `Карточка клиента "${data.login}"`}
            </h2>
        </div>
      </div>
      <div className="row mt-2 mt-lg-5">
        <div className="col-12 col-lg-3">
          <p className="textCardClientUnder">Имя</p>
          {loading? <span className="placeholder col-5 placeholder-lg"></span>: <p className="textCardClient">{data.name}</p>}
        </div>
        <div className="col-12 col-lg-3">
          <p className="textCardClientUnder">Город</p>
          {loading? <span className="placeholder col-5 placeholder-lg"></span>: <p className="textCardClient">{data.city}</p>}
        </div>
        <div className="col-12 col-lg-3">
          <p className="textCardClientUnder">Почта</p>
          {loading? <span className="placeholder col-5 placeholder-lg"></span>: <p className="textCardClient">{data.email}</p>}
        </div>
      </div>
      <div className="row mt-2 mt-lg-4">
        <div className="col-12 col-lg-3">
          <p className="textCardClientUnder">Фамилия</p>
          {loading? <span className="placeholder col-5 placeholder-lg"></span>: <p className="textCardClient">{data.surname}</p>}
        </div>
        <div className="col-12 col-lg-3">
          <p className="textCardClientUnder">Адрес</p>
          {loading? <span className="placeholder col-5 placeholder-lg"></span>: <p className="textCardClient">{data.adress}</p>}
        </div>
        <div className="col-12 col-lg-3">
          <p className="textCardClientUnder">Телефон</p>
          {loading? <span className="placeholder col-5 placeholder-lg"></span>: <p className="textCardClient">{data.phone}</p>}
        </div>
      </div>
      <div className="row mt-2 mt-lg-5">
        <div className="col-12 col-lg-8">
          <h2>Заказы клиента</h2>
          {loading? <span className="placeholder col-6 placeholder-lg"></span>: <p style={{color: 'red', fontWeight: 'bold'}}>Общий долг: {debt}</p>}
        </div>
        <div className="col-12 col-lg-4 text-start text-lg-end pt-0 pt-lg-4">
        <span onClick={handlePrint} style={{cursor:'pointer'}}><IoIosPrint size={20} style={{marginTop:'-5px'}}/> Распечтать заказы</span>
        
        </div>
      </div>
      <div className="row mt-4 testCard">
        <div className="col-12">
        <ComponentToPrint ref={componentRef} test={'251187'} loading={loading} orders={orders}/>  
        </div>
      </div>
    </div>
  );
};
export default CardClient;
