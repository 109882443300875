import { useForm } from 'react-hook-form';
import { useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const FormSendPassword = ({BaseUrl}) => {
  
  const { register, handleSubmit, reset } = useForm();
  const [msg, setMsg] = useState(null)
  const [show, setShow] = useState(false)
  const [load, setLoad] = useState(true)
  const onSubmit = async (data) => {
    setLoad(false)
    // reset()
    setMsg(null)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };
    const response = await fetch(`${BaseUrl}api/users/password`, requestOptions);
    if (response.ok) {
      setLoad(true)
      setShow(true)
      setMsg("Новый пароль отправлен на почту")
      // window.location.href = '/dashboard';
    } else {
      setLoad(true)
      setMsg("Нет такого логина или почты")
    }

  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input
        hidden={show}
        disabled={!load}
        type="text"
        className="form-control mt-5 auth_input mb-3"
        placeholder="Ваш логин или почта *"
        required
        {...register('login')}
      />
      <h5 className='mt-4 mb-3'>{msg}</h5>
      
      {load? 
      <button type="submit" className="btn btn-primary" hidden={show}>
      Отправить
    </button>
      : 
      <ClipLoader
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
      }
      
    </form>
  );
};
export default FormSendPassword;
