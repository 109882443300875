import logo from '../logo.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import { IoMdLogOut } from 'react-icons/io';
import { useEffect, useState } from 'react';

const HeaderClient = ({deleteItem, BaseUrl}) => {
  const [rate, setRate] = useState('')
  const [loadRate, setLoadRate] = useState(false)
  useEffect(() => {
    const getData = async () => {
      const response = await fetch(`${BaseUrl}api/orders/rate`);
      let actualData = await response.json();
      setRate((actualData['rate'])*100);
      setLoadRate(true)
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    return (
        <div className="container shadow p-3 rounded mt-3 white">
          <div className="row">
            <div className="col-6">
            <Link to="/"><img src={logo} className="logo mt-2" alt="logo" /></Link>
            
            </div>
            <div className="col-6 text-end">
            <button
                type="button"
                className="btn btn-outline-dark float-end"
                style={{ marginTop: 0 }}
                onClick={deleteItem}
              >
                Выйти <IoMdLogOut style={{ marginTop: -2 }} size={20} />
              </button>
            <p className='float-end me-3 mt-2'><b>{loadRate? `Курсы валют: 100 JPY = ${rate} ₽`: 'Загрузка...'}</b></p>
            </div>
          </div>
        </div>
      );
}
export default HeaderClient