import logo from '../logo.svg';
import { useForm } from 'react-hook-form';
import { FaSearch } from 'react-icons/fa';
import { MdOutlineCancel } from 'react-icons/md';
import RateForm from './RateForm';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

const HeaderAdmin = ({
  rate,
  BaseUrl,
  setData,
  setLoading,
  setRate,
  handleShow,
}) => {
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    let datefrom = '';
    let dateto = '';
    let client = '';

    if (data.datefrom !== '') {
      datefrom = `&datefrom=${data.datefrom}`;
    }
    if (data.dateto !== '') {
      dateto = `&dateto=${data.dateto}`;
    }
    if (data.client !== '') {
      client = `${data.client}`;
    }

    const requestOptions = {
      method: 'GET',
    };
    const response = await fetch(
      `${BaseUrl}api/orders/?search=${client}${datefrom}${dateto}`,
      requestOptions
    );
    if (response.ok) {
      let actualData = await response.json();
      setData(actualData['orders']);
      setLoading(false);
    } else {
    }
  };
  return (
    <div className="container shadow p-3 rounded mt-3 white">
      <div className="row">
        <div className="col-12 col-lg-5 col-xl-4 text-center text-md-start pt-1">
          <Link to="/"><img src={logo} className="logo" alt="logo" /></Link>
          <Button variant="primary" onClick={handleShow} className="ms-2 me-2">
            + Заказ
          </Button>
          <RateForm rate={rate} setRate={setRate} BaseUrl={BaseUrl} />
        </div>
        <div className="col-12 col-xxl-8 col-lg-7 col-xl-8">
          <form onSubmit={handleSubmit(onSubmit)} className="float-center float-lg-end mt-3 mt-lg-0">
            <div className='row'>
              <div className='col-6 col-lg-3 mb-2 mb-lg-0 p-1'>
                <Form.Label className='d-lg-none'>Дата с</Form.Label>
                <Form.Control type="date" className='' {...register('datefrom')}/>
              </div>
              <div className='col-6 col-lg-3 mb-2 mb-lg-0 p-1'>
                <Form.Label className='d-lg-none'>Дата по</Form.Label>
                <Form.Control type="date" className='' {...register('dateto')}/>
                </div>
              <div className='col-12 col-lg-6 mb-2 mb-lg-0 p-1'>
              <input
              type="text"
              className="form-control date_input ms-md-2 mb-3 mb-lg-0"
              
              aria-describedby="emailHelp"
              placeholder="Поиск..."
              {...register('client')}
            />
            <button
              type="submit"
              className="btn btn-primary ms-md-2"
              style={{ marginTop: -5 }}
            >
              <FaSearch />
            </button>
            <button
              type="submit"
              onClick={() => reset()}
              className="btn btn-danger ms-2 me-2"
              style={{ marginTop: -5 }}
            >
              <MdOutlineCancel size={20} style={{ marginTop: '-2px' }} />
            </button>
              </div>
            </div>
          
          
            
            
            
          </form>
        </div>
      </div>
    </div>
  );
};
export default HeaderAdmin;
