import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { InputMask } from '@react-input/mask';
import { useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const FormRegistration = (props) => {
  const { register, handleSubmit, reset} = useForm();
  const [loginBusy, setLoginBusy] = useState(true);
  const [email, setEmail] = useState(true)
  const [load, setLoad] = useState(false)
  const onSubmitEdit = async (data) => {
    setLoginBusy(true)
    setLoad(true)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };
    const response = await fetch(`${props.BaseUrl}api/users/registration`, requestOptions);
    if (response.status == 200) {
      setLoad(false)
      setLoginBusy(true)
      setEmail(true)
      props.setFormAuth(!props.formAuth); 
      props.setFormReg(!props.formReg)
      props.setMsgDone(false)
      reset()
    } else if(response.status == 404) {
      setLoad(false)
      setLoginBusy(false)
    } else if(response.status == 409) {
      setEmail(false)
      setLoad(false)
      setLoginBusy(true)
    }
    
  }
  return (
    <form className="text-start" onSubmit={handleSubmit(onSubmitEdit)} >
      <Form.Group className="mb-2">
        <Form.Label>Придумайте логин *</Form.Label>
        <Form.Control type="text" {...register('login')} required pattern="^[a-zA-Z0-9]+$" title="Только латинские буквы и цифры, не менее 5 символов" minLength="5" disabled={load}/>
        <p className='loginBusy' hidden={loginBusy}>Данный логин занят, придумайте другой</p>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>Имя *</Form.Label>
        <Form.Control type="text" {...register('name')} required disabled={load}/>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>Фамилия *</Form.Label>
        <Form.Control type="text" {...register('surname')} required disabled={load}/>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>Город *</Form.Label>
        <Form.Control type="text" {...register('city')} required disabled={load}/>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>Адрес *</Form.Label>
        <Form.Control type="text" {...register('adress')} required disabled={load}/>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>Телефон *</Form.Label>
        <InputMask className='form-control' mask="+7 (___) ___-__-__" replacement={{ _: /\d/ }} {...register('phone')} required disabled={load} placeholder='+7'/>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>Email *</Form.Label>
        <Form.Control type="email" {...register('email')} required disabled={load}/>
        <p className='loginBusy' hidden={email}>Данный email занят, придумайте другой</p>
      </Form.Group>
      <p className='text-end' style={{fontStyle:'italic'}}>* Обязательно</p>
      <div className="text-center">
      <ClipLoader 
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
                hidden={!load}
              />
        <button type="submit" className="btn btn-primary" hidden={load}>
          Регистрация
        </button>
      </div>
    </form>
  );
};
export default FormRegistration;
