import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import ClipLoader from 'react-spinners/ClipLoader';

const FormAddOrder = ({ BaseUrl }) => {
  const [rate, setRate] = useState(0);
  const [clients, setClients] = useState(null);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [put, setPut] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const [userid, setUserid] = useState(localStorage.user);

  const [total, setTotal] = useState(0);

  const handleChange = (event) => {
    setTotal((event.target.value * rate).toFixed(0));
  };

  useEffect(() => {}, [total]);

  const onSubmit = async (data) => {
    setPut(true);
    setLoading(true);

    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let currentDate = `${year}-${month}-${day}`;

    data.id_user = userid;
    data.total = total;
    data.date = currentDate;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };
    const response = await fetch(`${BaseUrl}api/orders/add`, requestOptions);
    if (response.ok) {
      window.location.href = '/dashboard';
    } else {
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(`${BaseUrl}api/orders/add`);
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        setClients(actualData['clients']);
        setStatus(actualData['status']);
        setRate(actualData['rate']);
        setError(null);
      } catch (err) {
        setError(err.message);
        setClients(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} className='FormAddOrder'>
        <div className="row">
          <div className="col-12 col-lg-6">
            <Form.Group className="mb-3">
              <Form.Label>Клиент *</Form.Label>
              {loading ? (
                <Form.Select disabled={true}>
                  <option>Загрузка данных</option>
                </Form.Select>
              ) : (
                <Select
                  options={clients}
                  placeholder={'Выбрать клиента'}
                  required
                  onChange={(newValue) => setUserid(newValue.value)}
                  autoFocus
                />
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Номер заказа *</Form.Label>
              <Form.Control
                type="text"
                disabled={put}
                {...register('number')}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Магазин</Form.Label>
              <Form.Control type="text" disabled={put} {...register('shop')} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Название</Form.Label>
              <Form.Control type="text" disabled={put} {...register('name')} />
            </Form.Group>
          </div>
          <div className="col-12 col-lg-6">
            <Form.Group className="mb-3">
              <Form.Label>Код *</Form.Label>
              <Form.Control
                type="text"
                disabled={put}
                {...register('sku')}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Ссылка</Form.Label>
              <Form.Control type="text" disabled={put} {...register('link')} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Кол-во</Form.Label>
              <Form.Control
                type="number"
                disabled={put}
                {...register('quantity')}
                defaultValue={1}
              />
            </Form.Group>
            <div className="row">
              <div className="col-12 col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>¥ JPY</Form.Label>
                  <Form.Control
                    type="number"
                    defaultValue={0}
                    min={0}
                    disabled={put}
                    {...register('jpy')}
                  />
                </Form.Group>
              </div>
              <div className="col-12 col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>¥ TOTAL JPY</Form.Label>
                  <Form.Control
                    {...register('jpy_total')}
                    onChange={handleChange}
                    type="number"
                    min={0}
                    defaultValue={0}
                    disabled={put}
                  />
                </Form.Group>
              </div>
              <div className="col-12 col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>Итого</Form.Label>
                  <Form.Control
                    value={total}
                    onChange={(e) => setTotal(e.target.value)}
                    type="number"
                    step="0.01"
                    min={0}
                    disabled={true}
                  />
                </Form.Group>
              </div>
              <Form.Group className="mb-3">
                <Form.Label>Статус</Form.Label>
                {loading ? (
                  <Form.Select disabled={true}>
                    <option>Загрузка данных</option>
                  </Form.Select>
                ) : (
                  <Form.Select
                    aria-label="Default select example"
                    disabled={put}
                    {...register('id_status')}
                    required
                  >
                    {status.map((status, index) => (
                      <option value={status.id} key={index}>
                        {status.text}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </Form.Group>
              <p className="text-end">* Обязательно</p>
            </div>
          </div>
        </div>
        {put ? (
          <ClipLoader
            className="float-end"
            size={40}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <Button
            variant="primary"
            type="submit"
            className="float-end"
            disabled={put}
          >
            Добавить
          </Button>
        )}

        <Form.Check
          className="float-end me-3 mt-1 checkPay"
          type="checkbox"
          id={1}
          value={1}
          label="Оплачен"
          {...register('pay')}
        />
      </Form>
    </div>
  );
};
export default FormAddOrder;
