import { useState } from 'react';
import logo from '../logo.svg';
import { useForm } from 'react-hook-form';
import ClipLoader from 'react-spinners/ClipLoader';
import FormRegistration from '../components/FormRegistration';
import FormSendPassword from '../components/FormSendPassword';
import { Link } from 'react-router-dom';

const AuthLayouts = ({ auth, setAuth, sendForm, deleteItem, BaseUrl}) => {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('') 
  const { register, handleSubmit } = useForm();
  
  const [formAuth, setFormAuth] = useState(false)
  const [formReg, setFormReg] = useState(true)
  const [sendPas, setSendPas] = useState(false)
  const [msgDone, setMsgDone] = useState(true)


  const onSubmit = async (data) => {
    setLoading(true);
    setText('');
    const requestOptions = {
      method: 'GET',
    };
    const response = await fetch(
      `${BaseUrl}api/users/get_user?login=${data.login}&password=${data.password}`,
      requestOptions
    );
    if (response.ok) {
      setLoading(false);
      let actualData = await response.json();
      sendForm(actualData.user);
      localStorage.setItem('user_id', actualData.user_id)
    } else {
      setLoading(false);
      setText('Не верный логин или пароль');
    }
  };
  return (
    <div className="container mt-0 mt-lg-5 p-4 p-lg-0">
      <div className="row justify-content-center">
        <div className="col-12  col-xs-12 col-sm-8 col-lg-4 white rounded shadow mt-5 text-center pt-5 pb-4">
          <Link to="/"><img src={logo} className="logo" alt="logo" /></Link>
          <div hidden={sendPas}>
          <form onSubmit={handleSubmit(onSubmit)} hidden={formAuth}>
            <p className='msgDoneRegistr' hidden={msgDone}>Спасибо за регистрацию!<br/>Данные для входа, отправлены на почту</p>
            <input
              type="text"
              className="form-control mt-5 auth_input"
              placeholder="Логин *"
              required
              {...register('login')}
            />
            <input
              type="password"
              className="form-control mt-3 auth_input mb-3"
              placeholder="Пароль *"
              required
              {...register('password')}
            />
            {loading ? (
              <ClipLoader
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              <button type="submit" className="btn btn-primary">
                Войти
              </button>
            )}
          </form>
          <p className='mt-3 text-danger'>{text}</p>
          <div hidden={formReg}>
            <FormRegistration BaseUrl={BaseUrl} setFormReg={setFormReg} setFormAuth={setFormAuth} formReg={formReg} formAuth={formAuth} msgDone={msgDone} setMsgDone={setMsgDone}/>
          </div>
          <p className='mt-3 mb-1 linkRegAuth' onClick={()=>{setMsgDone(true); setFormAuth(!formAuth); setFormReg(!formReg)}} style={{cursor:'pointer'}}>{formAuth?'Авторизация': 'Регистрация'}</p>
          </div>
          <div hidden={!sendPas} className='mt-3 mb-2'>
            <FormSendPassword BaseUrl={BaseUrl}/>
          </div>
          <p className='mt-0 linkRegAuth' hidden={formAuth} style={{cursor:'pointer'}} onClick={()=>{setSendPas(!sendPas);}}>{sendPas? 'Авторизация': 'Забыл пароль'}</p>
        </div>
      </div>
    </div>
  );
};
export default AuthLayouts;
