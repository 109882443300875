import { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useForm } from 'react-hook-form';

const RateForm = ({ rate, BaseUrl, setRate }) => {
  const { register, handleSubmit } = useForm();
  const [load, setLoad] = useState(false);
  const onSubmit = async (data) => {
    setRate('...');
    setLoad(true);
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };
    const response = await fetch(`${BaseUrl}api/users/rate`, requestOptions);
    if (response.ok) {
      setLoad(false);
      setRate(data.rate);
    } else {
    }
  };
  return (
    <Dropdown className="course_today">
      <Dropdown.Toggle
        variant="btn btn-outline-dark"
        id="dropdown-basic"
        style={{ fontWeight: 700 }}
      >
        ¥ {rate}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {load ? (
          'Обновление...'
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div class="input-group input-group-sm">
              <input
                {...register('rate')}
                className="form-control date_input input-group-sm"
                defaultValue={rate}
                type="number"
                step="0.01"
                min={0}
              />
              <button
                type="submit"
                className="btn btn-danger mt-2 btn-sm rounded"
                style={{ width: '100%' }}
              >
                Обновить
              </button>
            </div>
          </form>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default RateForm;
