import React from 'react';
import Table from 'react-bootstrap/Table';
import CheckBoxPay from './CheckBoxPay';

export const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      <div ref={ref}>
        <style type="text/css" media="print">{"\
        @page {\ size: landscape;\ }\
        body {\ font-size: 15px; line-height:1;\ }\
        "}</style>
        <div className='placeholder-glow'>
        <Table hover responsive="xl">
          <thead>
            <tr>
              <th></th>
              <th>Дата</th>
              <th>№</th>
              <th>Магазин</th>
              <th>Название</th>
              <th>Ссылка(код)</th>
              <th style={{width:'80px'}}>Кол-во</th>
              <th>JPY</th>
              <th>TOTAL JPY</th>
              <th>Итого</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {props.loading? 
          
          <tr>
          <td><span className="placeholder col-12 placeholder-lg"></span></td>
          <td><span className="placeholder col-12 placeholder-lg"></span></td>
          <td><span className="placeholder col-12 placeholder-lg"></span></td>
          <td><span className="placeholder col-12 placeholder-lg"></span></td>
          <td><span className="placeholder col-12 placeholder-lg"></span></td>
          <td><span className="placeholder col-12 placeholder-lg"></span></td>
          <td><span className="placeholder col-12 placeholder-lg"></span></td>
          <td><span className="placeholder col-12 placeholder-lg"></span></td>
          <td><span className="placeholder col-12 placeholder-lg"></span></td>
          <td><span className="placeholder col-12 placeholder-lg"></span></td>
          <td><span className="placeholder col-12 placeholder-lg"></span></td>
          </tr>
          : 
          props.orders.map((order,index)=>(
            <tr key={index}>
              <td><span className="status_cicle" style={{background:`${order.status.color}`, marginTop:'5px', marginLeft:'15px'}}></span></td>
              <td>{((order.date).split('-'))[2]}.{((order.date).split('-'))[1]}.{((order.date).split('-'))[0]}</td>
              <td>{order.number}</td>
              <td>{order.shop}</td>
              <td>{order.name}</td>
              <td><a href={order.link} target='_blank' style={{color:'black'}}>{order.sku}</a></td>
              <td style={{textAlign:'center'}}>{order.quantity}</td>
              <td>{order.jpy}</td>
              <td>{order.jpy_total}</td>
              <td style={{fontWeight:700}}>{order.total}₽</td>
              <td><CheckBoxPay pay={order.pay}/></td>
              <td></td>
            </tr>
          ))
          }
          </tbody>
        </Table>
            

        </div>
      </div>
    );
  });