import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';

import { MainLayouts } from './layouts/MainLayouts';
import AuthLayouts from './layouts/AuthLayouts';
import HomeAdmin from './components/HomeAdmin';
import HomeClient from './components/HomeClient';
import ClientLayouts from './layouts/ClientLayouts';
// import FormAddOrder from './components/FormAddOrder';
// import FormDeleteOrder from './components/FormDeleteOrder';
import IndexPage from './components/IndexPage';
import PrintPage from './components/PrintPage';

function App() {
  
  const BaseUrl = 'https://api-mitsuefu.ru/';
  const [auth, setAuth] = useState('');

  

  const [admin, setAdmin] = useState(false);

  const sendForm = (id) => {
    if (id === true) {
      setAdmin(false);
      setAdmin(true);
    } else {
      setAdmin(false);
      setAdmin(false);
    }
    let token = uuidv4();
    localStorage.setItem('token', id);
    setAuth(token);
  };

  const deleteItem = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    setAuth('');
  };

  useEffect(() => {
    const getData = () => {
      let token = localStorage.getItem('token');

      if (token === 'true') {
        setAdmin(true);
      }

      if (token == null) {
      } else {
        setAuth(uuidv4());
      }
    };
    getData();
  }, []);

  

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route index element={<IndexPage BaseUrl={BaseUrl}/>} /> 
          <Route path="/dashboard">
            {auth ? (
             admin ? 
             <Route element={<MainLayouts deleteItem={deleteItem} />}>
                  <Route index element={<HomeAdmin BaseUrl={BaseUrl} deleteItem={deleteItem}/>} />
                  <Route path='test' element={<PrintPage/>} />
             </Route>
             : 
             <Route element={<ClientLayouts deleteItem={deleteItem} BaseUrl={BaseUrl}/>}>
                  <Route index element={<HomeClient BaseUrl={BaseUrl}/>} />
             </Route>
            ) : (
              <Route
                index
                element={
                  <AuthLayouts
                    auth={auth}
                    setAuth={setAuth}
                    sendForm={sendForm}
                    deleteItem={deleteItem}
                    BaseUrl={BaseUrl}
                  />
                }
              />
            )}
          </Route>
        </Routes>
        {/* {auth ? (
          admin ? (
            <div>
              <Routes>
                <Route
                  element={<MainLayouts deleteItem={deleteItem} />}
                  path="/"
                >
                  <Route index element={<HomeAdmin BaseUrl={BaseUrl} deleteItem={deleteItem}/>} />
                  <Route element={<FormAddOrder BaseUrl={BaseUrl} deleteItem={deleteItem}/>} path="/add"/>
                  <Route element={<FormDeleteOrder BaseUrl={BaseUrl}/>} path="/delete/:id"/>
                </Route>
              </Routes>
            </div>
          ) : (
            <div>
              <Routes>
                <Route
                  element={<ClientLayouts deleteItem={deleteItem} />}
                  path="/"
                >
                  <Route index element={<HomeClient />} />
                </Route>
              </Routes>
            </div>
          )
        ) : (
          <AuthLayouts
            auth={auth}
            setAuth={setAuth}
            sendForm={sendForm}
            deleteItem={deleteItem}
            BaseUrl={BaseUrl}
          />
        )} */}
      </div>
    </BrowserRouter>
  );
}
export default App;
