import Table from 'react-bootstrap/Table';
import { useState, useEffect } from 'react';
import CheckBoxPay from './CheckBoxPay';

const HomeClient = ({BaseUrl}) => {
  
  const dataSkeleteon = [1,2,3,4,5,7,8];
  const user_id = localStorage.getItem('user_id');
  const [orders, setOrders] = useState([])
  const [debt, setDebt] = useState([])
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const response = await fetch(`${BaseUrl}api/users/get_client/${user_id}`);
      let actualData = await response.json();
      setOrders(actualData['orders'])
      setDebt(actualData['debt'])
      setLoading(false)
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container rounded shadow mt-3 white pt-3 pb-3 text-center">
      <div className="row ps-4 pt-2">
        <div className="col-12 text-start mt-4 ps-3">
          <h3 style={{fontWeight:700, color:'#152536'}}>Мои заказы</h3>
          {loading? <span className="placeholder col-2 placeholder-lg"></span>: <p style={{color: 'red', fontWeight: 'bold'}}>Мой долг: {debt}</p>}
        </div>
        <div className='col-12 placeholder-glow mt-3'>
        <Table hover responsive="xl">
          <thead>
            <tr>
              <th>Дата</th>
              <th>Номер заказа</th>
              <th>Магазин</th>
              <th>Название</th>
              <th>Ссылка(код)</th>
              <th>Кол-во</th>
              <th>Итого</th>
              <th>Статус</th>
              <th>Оплачен</th>
            </tr>
          </thead>
          <tbody>
            {loading? 
            dataSkeleteon.map((index)=>(
              <tr key={index}>
              <td><span className="placeholder col-5 placeholder-lg"></span></td>
              <td><span className="placeholder col-5 placeholder-lg"></span></td>
              <td><span className="placeholder col-5 placeholder-lg"></span></td>
              <td><span className="placeholder col-5 placeholder-lg"></span></td>
              <td><span className="placeholder col-5 placeholder-lg"></span></td>
              <td><span className="placeholder col-5 placeholder-lg"></span></td>
              <td><span className="placeholder col-5 placeholder-lg"></span></td>
              <td><span className="placeholder col-5 placeholder-lg"></span></td>
              <td><span className="placeholder col-5 placeholder-lg"></span></td>
            </tr>
            ))
            :
            orders.map((order,index)=>(
              <tr key={index}>
                <td>{((order.date).split('-'))[2]}.{((order.date).split('-'))[1]}.{((order.date).split('-'))[0]}</td>
                <td>{order.number}</td>
                <td>{order.shop}</td>
                <td>{order.name}</td>
                <td><a href={order.link} target='_blank' rel="noreferrer" style={{color:'black'}}>{order.sku}</a></td>
                <td>{order.quantity}</td>
                <td style={{fontWeight:700}}>{order.total}₽</td>
                <td>{order.status.text}</td>
                <td><CheckBoxPay pay={order.pay}/></td>
              </tr>
            ))
            }
          </tbody>
        </Table>
        </div>
      </div>
    </div>
  );
};
export default HomeClient;
