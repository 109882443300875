import Form from 'react-bootstrap/Form';
const CheckBoxPay = ({pay}) => {
    if(pay === 0){
        return (
          ''
          //   <Form.Check className='checkPay'
          //   disabled
          //   value={1}
          //   checked={false}
          //   type="checkbox"
          // />
        )
    }
    else {
        return (
            <Form.Check className='checkPay'
            disabled
            checked={true}
            type="checkbox"
          />
        )
    }
}
export default CheckBoxPay