import { FaEllipsisV } from 'react-icons/fa';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaRegEdit, FaRegUser, FaRegTrashAlt   } from "react-icons/fa";
import { Link } from "react-router-dom";
import CheckBoxPay from './CheckBoxPay';

const OrderRowAdmin = ({order, handleShowDelete, handleShowEdit, handleCardClient}) => {
  return (
    <tr className='p-0'>
      <td>
        <span className="status_cicle" style={{background:`${order.status.color}`, marginTop:'5px', marginLeft:'15px'}}></span>
      </td>
      <td>{((order.date).split('-'))[2]}.{((order.date).split('-'))[1]}.{((order.date).split('-'))[0]}</td>
      <td style={{lineHeight:0.9}}>{order.user.login}<br/><span style={{fontSize:'12px', color:'#ababab'}}>{order.user.phone}</span></td>
      <td>{order.number}</td>
      <td>{order.shop}</td>
      <td>{order.name}</td>
      <td><a href={order.link} target='_blank' style={{color:'black'}}>{order.sku}</a></td>
      <td>{order.quantity}</td>
      <td>{order.jpy}</td>
      <td>{order.jpy_total}</td>
      <td style={{fontWeight:700}}>{order.total}₽</td>
      <td><CheckBoxPay pay={order.pay}/></td>
      <td>
        <Dropdown className='dropdownRow'>
          <Dropdown.Toggle variant="success" id="dropdown-basic" className='p-0 threedots'>
            <FaEllipsisV className='ico_three_dots' />
          </Dropdown.Toggle>
          <Dropdown.Menu style={{width:'180px'}}>
          <Link className="btn btn-primary ms-2 me-2 linkDropDownOrder" onClick={()=>handleShowEdit(order.user.id, order.user.login, order.user.phone, order.id, order.total)}><FaRegEdit className='subMenuIco' /> Редактировать</Link>
          <Link className="btn btn-primary ms-2 me-2 linkDropDownOrder" onClick={()=>handleCardClient(order.user.id)}><FaRegUser className='subMenuIco'/> Карточка клиента</Link>
          <Link className="btn btn-primary ms-2 me-2 linkDropDownOrder" onClick={()=>handleShowDelete(order.id, order.number)}><FaRegTrashAlt className='subMenuIco'/> Удалить</Link>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
};
export default OrderRowAdmin;
