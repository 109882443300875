import { Outlet } from "react-router-dom";
export const MainLayouts = ({deleteItem}) => {
  return (
    <div className="container mb-5">
      <div className="row">
        <Outlet />
      </div>
    </div>
  )
}
export default MainLayouts